import {createApp} from 'vue'
import {createRouter, createWebHistory} from 'vue-router'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import AboutPage from "@/views/AboutPage";
import HomePage from "@/views/HomePage";
import FormPage from "@/views/FormPage";
import TermsService from "@/views/TermsService";


// Création des routes et du router.
// Toutes les routes de l'application. Une route correspond à "une page", et a une URL
// bien distincte. Par exemple:
// http://localhost:8080/privacy-policy-generator/static/a
// http://localhost:8080/privacy-policy-generator/static/b
// te permet de faire 2 routes a et b avec 2 pages différentes. Cela te permet d'avoir une SPA qui
// a plusieurs pages comme un site classique.

const routes = [
    {path: '/', component: FormPage},
    {path: '/home', component: HomePage},
    {path: '/about', component: AboutPage},
    {path: '/terms-of-service', component: TermsService},
];
const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_PATH),
    routes: routes,
})



const app = createApp(App)
app.use(router);
app.mount('#app')
