<template>
  <div>

<header class="vff-header">
  <div class="f-container">
    <a style="text-decoration: none;" href="https://www.google.com" v-on:click.prevent="this.$refs.flowform.reset()"><svg width="300" height="50" viewBox="0 0 300 50" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <text id="PPG" xml:space="preserve" x="64" y="49" text-anchor="middle" font-family="Arial Black" font-size="58" font-weight="700" fill="#41b883">PPG</text>
      <text id="Privacy-Policy-Generator" xml:space="preserve" x="205" y="47" text-anchor="middle" font-family="Arial Black" font-size="11" font-weight="700" fill="#41b883" text-decoration="underline">Privacy Policy Generator</text>
      <text id="Bta" xml:space="preserve"><tspan x="143" y="8" text-anchor="middle" font-family="Arial Black" font-size="11" font-weight="700" fill="#41b883" xml:space="preserve">(</tspan><tspan font-family="Arial Black" font-size="11" font-weight="700" fill="#ed230d" xml:space="preserve">Bêta</tspan><tspan font-family="Arial Black" font-size="11" font-weight="700" fill="#41b883" xml:space="preserve">)</tspan></text>
    </svg></a>

  </div>
</header>



<!---->
<flow-form ref="flowform" v-on:complete="onComplete" v-on:submit="onSubmit" v-bind:questions="questions"
           v-bind:language="language" v-bind:standalone="true">



  <!-- oho test Tiptap -->
  <div class="vff">
  <div class="text-success">

    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/">Home</router-link>
        </li>
      </ol>
    </nav>

      <center><h1>Terms of Service</h1></center>

      <h4>Acceptance of Terms</h4>
      <p>By accessing or using the API provided by our company ("Company"), you agree to be bound by these terms and conditions ("Terms of Service"), which constitute a legal agreement between you and the Company. If you do not agree to these Terms of Service, you may not access or use the API.</p>
      <br>


      <h4>Description of Service</h4>
      <p>The API is a tool that generates privacy policies for mobile applications based on pre-established templates and input parameters such as the application name, company name, headquarters location, personal data collected by the company, DPO email address, etc. The API also accepts basic HTML text as input and transforms it into an HTML page that can be customized with various graphical designs.</p>
      <br>

      <h4>Use of Service</h4>
      <p>You may use the API solely for the purpose of generating privacy policies for mobile applications or transforming basic HTML text into an HTML page. You may not use the API for any other purpose or in any way that violates these Terms of Service or any applicable laws or regulations.</p>
      <br>

      <h4>No Warranty</h4>
      <p>The API is provided free of charge and without any warranty of fitness for purpose. The Company makes no representation or warranty that the API will be error-free or uninterrupted, or that any defects will be corrected.</p>
      <br>

      <h4>Limitation of Liability</h4>
      <p>To the maximum extent permitted by law, the Company shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use of the API, even if the Company has been advised of the possibility of such damages.</p>
      <br>

      <h4>Intellectual Property</h4>
      <p>All intellectual property rights in the API and any associated documentation belong to the Company or its licensors. You may not reproduce, distribute, modify, or create derivative works based on the API or any associated documentation without the Company's prior written consent.</p>
      <br>

      <h4>Termination</h4>
      <p>The Company may terminate these Terms of Service and your access to the API at any time, without notice or liability, for any reason whatsoever.</p>
      <br>

      <h4>Governing Law</h4>
      <p>These Terms of Service shall be governed by and construed in accordance with the French laws and exclusive jurisdiction of French court. Any disputes arising out of or in connection with these Terms of Service shall also be subject to the exclusive jurisdiction of French courts and French law.</p>
      <br>

      <h4>Changes to Terms of Service</h4>
      <p>The Company reserves the right to modify these Terms of Service at any time, without notice or liability, by posting a revised version on its website. Your continued use of the API following any such modification constitutes your acceptance of the modified Terms of Service.</p>
      <br>

      <h4>Entire Agreement</h4>
      <p>These Terms of Service constitute the entire agreement between you and the Company regarding the use of the API and supersede all prior agreements and understandings, whether written or oral, relating to the subject matter hereof.</p>
      <br>
  </div>
  </div>
  <!-- Tiptap end -->


</flow-form>

</div>

</template>

<script>
export default {
  name: 'TermsService',
}
</script>